<template>
  <div class="agentDetail">
    <div class="agentInfo">
      <div class="headimage">
        <el-avatar :size="60" :src="agentDetail.headPic"></el-avatar>
        <p>{{ agentDetail.distributorName || '—' }}</p>
      </div>
      <div class="headText">
        <div>
          <span class="colr1 ph1">手机号码：</span>
          <span class="colr2">{{ agentDetail.mobile || '—' }}</span>
        </div>
        <div class="item-cardId">
          <span class="colr1 ph1">所属地区：</span>
          <span class="colr2">{{ agentDetail.location || '—' }}</span>
        </div>
      </div>
      <div class="headInfo">
        <div>
          <span class="colr1">代理等级：</span>
          <span v-if="agentDetail.agencyLevel == 1" class="colr2">一级</span>
          <span v-if="agentDetail.agencyLevel == 2" class="colr2">二级</span>
        </div>
        <div class="addtime">
          <span class="colr1">添加时间：</span>
          <span class="colr2">{{ agentDetail.createTime || '—' }}</span>
        </div>
        <div class="action">
          <span class="colr1 ph2 texttop">备注：</span>
          <span class="colr2 text">{{ agentDetail.remark || '—' }}</span>
        </div>
      </div>
      <div class="headCardImage">
        <span>相关资质：</span>
        <span v-if="agentDetail.appendixUrl == ''">—</span>
        <div v-if="agentDetail.appendixUrl != ''">
          <img :src="agentDetail.appendixUrl" />
        </div>
      </div>
    </div>
    <!-- 分销数据表格 -->
    <div class="agentData">
      <table-list
        title="分销数据"
        :loading="loading"
        :data="dataList"
        :columns="columns(this)"
        :btns="btns(this)"
        :pager="pager"
        :tabs-list="OptionsList"
        @search="onSearch"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <div slot="filter" class="subTitle">
          <span class="textDes">累计成交金额：</span>
          <span class="textMon">￥{{ agentDetail.transactionAmount }}</span>
        </div>
      </table-list>
    </div>
  </div>
</template>
<script>
//表格tabs
const OptionsList = [
  {
    prop: 'productType',
    activeValue: '',
    children: [
      { value: 3, label: '证书' },
      { value: 2, label: '课程' },
      { value: 1, label: '班级' },
    ],
  },
  {
    prop: 'timeType',
    activeValue: '',
    children: [
      { value: 1, label: '今日' },
      { value: 2, label: '本月' },
      { value: 3, label: '半年' },
    ],
  },
  {
    prop: 'onlineFlag',
    activeValue: '',
    children: [
      { value: 1, label: '线上' },
      { value: 2, label: '线下' },
    ],
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleexportData,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'orderNoDetail',
    label: '订单编号',
    minWidth: 175,
  },
  {
    prop: 'productTypeName',
    label: '类型',
    minWidth: 48,
  },
  {
    prop: 'productName',
    label: '商品名称',
    minWidth: 160,
    twoLines: true,
  },
  {
    prop: 'standards',
    label: '规格',
    minWidth: 120,
  },
  {
    prop: 'source',
    label: '来源',
    minWidth: 48,
  },
  {
    prop: 'orderAmount',
    label: '订单金额',
    minWidth: 100,
    align: 'right',
    formatter: row => {
      return '￥' + row.orderAmount
    },
  },
  {
    prop: 'payAmount',
    label: '实付金额',
    minWidth: 100,
    align: 'right',
    formatter: row => {
      return '￥' + row.payAmount
    },
  },
  {
    prop: 'payType',
    label: '支付渠道',
    minWidth: 76,
  },
  {
    prop: 'payTime',
    label: '支付时间',
    minWidth: 150,
  },
  {
    prop: 'userName',
    label: '购买人名称',
    minWidth: 90,
  },
  {
    prop: 'phone',
    label: '手机号',
    minWidth: 110,
  },
]
import TableList from '@/components/TableList'
import { agentDetail, agentOrderInfo, agentOrderExport } from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'Agentdetail',
  components: {
    TableList,
  },
  data() {
    return {
      OptionsList,
      loading: false,
      btns,
      columns,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      dataList: [],
      distributorUserId: this.$route.query.distributorUserId,
      agentDetail: {},
    }
  },
  mounted() {
    this.handleSearchAgentDetail()
    this.handleOrderInfo()
  },
  methods: {
    //查询代理商详情
    async handleSearchAgentDetail() {
      const [res, err] = await to(agentDetail({ distributorUserId: this.distributorUserId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.agentDetail = res.data
    },
    //订单详情查询
    async handleOrderInfo() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        agentOrderInfo({
          distributorUserId: this.distributorUserId,
          current,
          size,
          ...this.queryParams,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.handleOrderInfo()
    },
    //导出
    async handleexportData() {
      const { current, size } = this.pager
      const [res, err] = await to(
        agentOrderExport({
          distributorUserId: this.distributorUserId,
          current,
          size,
          ...this.queryParams,
        }),
      )
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      let name = this.agentDetail.distributorName
      a.download = name + '.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handleOrderInfo()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handleOrderInfo()
    },
  },
}
</script>
<style scoped lang="scss">
.agentInfo {
  display: inline-block;
  width: 100%;
  height: 156px;
  background: #ffffff;
  display: flex;
  .headimage {
    margin: 20px 0 0 22px;
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #333333;
    }
  }
  .headText {
    margin: 20px 0 0 5.31%;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    .colr1 {
      color: #606266;
    }
    .colr2 {
      color: #333333;
    }
    .ph1 {
      padding-left: 14px;
    }
    .item-cardId {
      margin: 24px 0;
    }
  }
  .headInfo {
    margin: 20px 0 0 10.42%;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    .colr1 {
      color: #606266;
    }
    .colr2 {
      color: #333333;
    }
    .ph2 {
      padding-left: 28px;
    }
    .addtime {
      margin: 24px 0;
    }
    .action {
      display: flex;
      .texttop {
        padding-top: 2px;
      }
      .text {
        display: block;
        width: 230px;
        line-height: 18px;
      }
    }
  }
  .headCardImage {
    margin: 20px 0 0 11.98%;
    display: flex;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #606266;
    }
    img {
      width: 116px;
      height: 116px;
      opacity: 1;
      border-radius: 0px;
    }
  }
}
.agentData {
  .subTitle {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-left: 20px;
    border-left: 1px solid #e2e2e2;
    padding-left: 20px;
    line-height: 20px;
    .textDes {
      color: #909399;
    }
    .textMon {
      color: #ff7b33;
    }
  }
}
@media screen and (max-width: 1300px) {
  .agentInfo {
    .headText {
      margin: 20px 0 0 3.31%;
    }
    .headInfo {
      margin: 20px 0 0 8.42%;
    }
    .headCardImage {
      margin: 20px 0 0 8.98%;
    }
  }
}
@media screen and (max-width: 1300px) {
  .agentInfo {
    .headText {
      margin: 20px 0 0 3.31%;
    }
    .headInfo {
      margin: 20px 0 0 5.42%;
    }
    .headCardImage {
      margin: 20px 0 0 5.98%;
    }
  }
}
</style>
